<template>
  <div class="bu-ju-content">
    <div class="bu-ju-body">
      <nav-header fixed title="修改密码"/>
      <van-form @submit="onSubmit">
        <div class="margin-top-15">
          <van-cell-group inset>
            <van-field
                required
                v-model="form.mobile"
                label="手机号"
                placeholder="请输入手机号"
            />
            <van-field
                required
                v-model="form.code"
                label="验证码"
                placeholder="请输入验证码"
            >
              <template #button>
                <a href="javascript:" @click="getCode(form.mobile)">{{ getCodeTitle }}</a>
              </template>
            </van-field>
          </van-cell-group>
        </div>
        <div class="margin-top-15">
          <van-cell-group inset>
            <van-field
                required
                type="password"
                v-model="form.user_pass"
                label="新密码"
                placeholder="请输入新密码"
            />
            <van-field
                required
                v-model="form.user_pass_2"
                type="password"
                label="再次输入"
                placeholder="请再次输入新密码"
            />
          </van-cell-group>
        </div>

        <div class="form-btn">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
      <no-mobile-tip/>
    </div>
    <div class="bu-ju-footer">
      <footer-lian-xike-fu :fixed="false" />
    </div>
  </div>
</template>

<script>
//修改密码
import yanZhengMa from "../open/yanZhengMa";
import http from "../../api/http";
import NoMobileTip from "./public/noMobileTip";
import FooterLianXikeFu from "../open/footerLianXikeFu";
import NavHeader from "../../components/top/NavHeader";

export default {
  name: 'EditPassword',
  components: {NavHeader, FooterLianXikeFu, NoMobileTip},
  mixins: [yanZhengMa],
  data() {
    return {
      form: {}
    }
  },
  mounted() {
  },
  methods: {
    onSubmit() {
      http.post('/gzhphp/login/EditPassword', this.form, {load_success: true}).then(da => {
        if (da.code === 1) {
          this.$router.push({name: 'NumberLogin'})
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
.bu-ju-content {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-around;
  align-items: stretch;
  height: 100%;
  overflow-y: auto;
  .bu-ju-body {
    overflow-y: auto;
    flex: 1;
  }
  .bu-ju-footer {
    height: 80px;
  }
}
</style>
